import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

router.beforeEach((to, from, next) => {
  const defaultTitle = "Vote Now";
  const title = to.meta?.title as string | undefined;
  document.title = title || defaultTitle;
  next();
});

createApp(App).use(router).mount("#app");
