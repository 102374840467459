<template>
  <div
    class="poll-preview-card"
    @click="$router.push(`/webapps/${serverName}/${webAppId}/${poll._id}/vote`)"
  >
    <div class="poll-info">
      <div v-if="featured" class="featured-badge">
        <img src="@/assets/images/fire.png" alt="Featured" class="icon" />
        <span class="featured-label">Featured</span>
      </div>
      <div v-if="!featured" class="top-spacing"></div>
      <div class="poll-question">{{ poll.question }}</div>
      <div class="poll-description">{{ poll.description }}</div>
    </div>
    <div class="poll-image-container">
      <div
        class="poll-image"
        :style="{ backgroundImage: 'url(' + poll.questionImage + ')' }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Poll } from "@/types/Poll";

export default defineComponent({
  props: {
    poll: {
      type: Object as PropType<Poll>,
      required: true,
    },
    featured: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      serverName: this.$route.params.serverName,
      webAppId: this.$route.params.webAppName,
    };
  },
});
</script>

<style scoped>
.poll-preview-card {
  display: flex;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
  margin-bottom: 15px;
}

.poll-image-container {
  width: 25%;
  overflow: hidden;
  background: white;
}

.poll-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.poll-info {
  flex: 1;
  padding: 7px 10px 10px 10px;
}

.poll-question {
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  margin-bottom: 4px;
}

.poll-description {
  font-size: 9px;
  color: #888;
  font-weight: 400;
}

.featured-badge {
  background-color: #ffff;
  border-radius: 16px;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 7px;
  font-size: 11px;
  color: grey;
  font-weight: 500;
  margin-top: 3px;
}

.icon {
  width: 13px;
  height: 13px;
  margin-right: 3px;
}

.top-spacing {
  padding-top: 5px;
}

.featured-label {
  margin-right: 2px;
}

/* smaller mobile devices */
@media (max-width: 320px) {
  .featured-badge {
    font-size: 9px;
  }
  .icon {
    width: 12px;
    height: 12px;
  }
  .poll-question {
    font-size: 13px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) {
  .poll-preview-card {
    min-height: 80px;
  }
  .featured-badge {
    font-size: 13px;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
  .poll-question {
    font-size: 18px;
    margin-top: 3px;
  }
  .poll-description {
    font-size: 13px;
  }
  .poll-info {
    padding: 7px 15px 10px 15px;
  }
}
</style>
