import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import WebAppsView from "@/views/WebAppsView.vue";
import PollsView from "@/views/PollsView.vue";
import VoteConfirmationView from "@/views/VoteConfirmationView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/webapps/:serverName/:webAppName",
    name: "webapps",
    component: WebAppsView,
    meta: {
      title: "Discover Polls",
    },
  },
  {
    path: "/webapps/:serverName/:webAppName/:pollName/vote",
    name: "polls",
    component: PollsView,
    meta: {
      title: "Vote Now",
    },
  },
  {
    path: "/webapps/:serverName/:webAppName/:pollName/confirmation",
    name: "confirmation",
    component: VoteConfirmationView,
    meta: {
      title: "Vote Confirmation",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
