<template>
  <div class="page" :style="{ backgroundColor: 'white' }">
    <div v-if="webAppData != null">
      <div class="image-container">
        <img :src="webAppBanner" alt="Banner Image" class="image" />
        <div v-if="featuredPoll" class="featured-poll-card">
          <div class="featured-poll-header">
            <div class="header-container">
              <div class="featured-header-title">
                <span>{{ webAppData.title }}</span>
                <span :style="{ color: primaryColor, marginLeft: '2px' }"
                  >Fan Vote
                </span>
              </div>
              <div class="featured-badge">
                <img
                  src="@/assets/images/fire.png"
                  alt="Featured"
                  class="icon"
                />
                <span class="featured-label">Featured</span>
              </div>
            </div>
          </div>
          <div class="featured-poll-body">
            <h3 class="featured-question">{{ featuredPoll.question }}</h3>
            <button
              class="vote-now-button"
              :style="{ backgroundColor: primaryColor }"
              @click="
                $router.push(
                  `/webapps/${serverName}/${webAppId}/${featuredPoll._id}/vote`
                )
              "
            >
              Vote now
            </button>
          </div>
        </div>
      </div>
      <div class="content">
        <div v-if="featuredPoll" class="featured-poll-gap"></div>
        <div v-if="filteredPolls.length == 0" class="check-back-card">
          <CheckBackPlaceholder
            :webAppTitle="webAppData.title"
            :primaryColor="primaryColor"
          />
        </div>
        <div v-if="filteredPolls.length > 0">
          <h3 class="more-heading">
            <span :style="{ color: primaryColor }">More from </span>
            <span>{{ webAppData.title }}</span>
          </h3>
          <div class="polls-list">
            <LargePollPreviewCard
              v-for="poll in filteredPolls"
              :key="poll._id"
              :poll="poll"
              :primaryColor="primaryColor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import brotliPromise from "brotli-wasm";
import { WebApp } from "@/types/WebApp";
import { Poll } from "@/types/Poll";
import LargePollPreviewCard from "@/components/LargePollPreviewCard.vue";
import CheckBackPlaceholder from "@/components/CheckBackPlaceholder.vue";

export default defineComponent({
  name: "WebAppsView",
  components: {
    LargePollPreviewCard,
    CheckBackPlaceholder,
  },
  data() {
    return {
      webAppData: null as WebApp | null,
      selectedOption: null as string | null,
      serverName: this.$route.params.serverName,
      webAppId: this.$route.params.webAppName,
      primaryColor: "",
      secondaryColor: "",
      polls: [] as Poll[],
      featuredPoll: null as Poll | null,
      defaultBannerImage: require("@/assets/images/default-banner.png"),
    };
  },
  computed: {
    webAppBanner(): string {
      return this.webAppData?.banner.url || this.defaultBannerImage;
    },
    filteredPolls(): Poll[] {
      // only show active polls and do not repeat the featured poll
      return this.polls.filter(
        (poll) =>
          poll.pollStatus === "active" && poll._id !== this.featuredPoll?._id
      );
    },
  },
  // Assuming you're serving the JSON file from the same server
  mounted() {
    // fetch WebApp data
    fetch(`/webapps/${this.serverName}/${this.webAppId}/webapp.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch WebApp data (${response.status} ${response.statusText})`
          );
        }
        // read the response body into a buffer
        return response.arrayBuffer().then((buffer) => {
          const uint8Array = new Uint8Array(buffer);
          // attempt to decompress the response
          return brotliPromise.then((brotli) => {
            try {
              // try decompressing
              const decompressedData = brotli.decompress(uint8Array);
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(decompressedData);
              return JSON.parse(jsonString);
            } catch (error) {
              // if decompression fails - parse JSON directly from the buffer
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(uint8Array);
              return JSON.parse(jsonString);
            }
          });
        });
      })
      .then((webAppData) => {
        // set WebApp data
        this.webAppData = webAppData as WebApp;
        // set the colour scheme using the chosen WebApp theme colours
        const colorScheme = JSON.parse(this.webAppData.theme);
        this.primaryColor = colorScheme.mainColor;
        this.secondaryColor = colorScheme.secondaryColor;
        // set poll data belonging to that WebApp
        if (webAppData.apps) {
          this.polls = this.webAppData.apps;
        }
        // set the featured poll
        if (webAppData.featuredPoll) {
          const featuredPollId = this.webAppData.featuredPoll;
          const featuredPoll =
            this.polls.find((poll) => poll._id === featuredPollId) || null;
          // only display the featured poll if it's active
          if (featuredPoll && featuredPoll.pollStatus === "active") {
            this.featuredPoll = featuredPoll;
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching WebApp data:", error);
      });
  },
});
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container {
  position: relative;
  height: 24.582vh;
}

.featured-poll-card {
  background-color: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.featured-poll-header {
  background-color: #f7f7f7e6;
  padding: 8px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #dddd;
}

.featured-header-title {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.featured-badge {
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
}

.featured-label {
  font-size: 9px;
  color: black;
  margin-right: 2px;
}

.icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.featured-poll-body {
  padding: 5px 32px 16px 32px;
}

.featured-question {
  margin-top: 5px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 17px;
}

.vote-now-button {
  color: #fff;
  border: none;
  padding: 4px 8px;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
  font-size: 12px;
}

.featured-poll-gap {
  padding-top: 80px;
}

.content {
  padding: 10px 10% 25px;
}

.more-heading {
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}

.polls-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.check-back-card {
  padding-top: 85px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* smaller mobile devices */
@media (max-width: 320px) {
  .more-heading {
    font-size: 15px;
  }
  .featured-poll-gap {
    padding-top: 45px;
  }
  .featured-header-title {
    font-size: 10px;
  }
  .featured-question {
    font-size: 12px;
  }
  .featured-poll-body {
    padding: 5px 28px 13px;
  }
  .featured-label {
    font-size: 7px;
  }
  .icon {
    width: 10px;
    height: 10px;
  }
  .vote-now-button {
    padding: 3px 8px;
    font-size: 10px;
  }
}

/* medium-small mobile devices */
@media (min-width: 321px) and (max-width: 373px) {
  .featured-poll-gap {
    padding-top: 75px;
  }
  .featured-question {
    font-size: 14px;
  }
  .vote-now-button {
    font-size: 10px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) {
  .more-heading {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .featured-poll-gap {
    padding-top: 100px;
  }
  .featured-header-title {
    font-size: 14px;
  }
  .featured-label {
    font-size: 12px;
  }
  .icon {
    width: 14px;
    height: 14px;
  }
  .featured-question {
    font-size: 19px;
    margin-bottom: 15px;
  }
  .vote-now-button {
    padding: 4px 8px;
    font-size: 14px;
  }
  .featured-poll-body {
    padding: 10px 30px 20px;
  }
}
</style>
