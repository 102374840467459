<template>
  <div class="discover">
    <h4 :style="{ color: primaryColor }">
      Discover more from {{ webAppName }}
    </h4>
    <div v-if="featuredPoll">
      <PollPreviewCard :poll="featuredPoll" :featured="true" />
    </div>
    <div v-for="poll in polls" :key="poll._id">
      <PollPreviewCard :poll="poll" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import PollPreviewCard from "@/components/PollPreviewCard.vue";
import { Poll } from "@/types/Poll";

export default defineComponent({
  name: "DiscoverPolls",
  props: {
    primaryColor: String,
    secondaryColor: String,
    webAppName: String,
    polls: {
      type: Array as PropType<Poll[]>,
      required: true,
    },
    featuredPoll: {
      type: Object as PropType<Poll | null>,
      default: null,
      required: false,
    },
  },
  components: {
    PollPreviewCard,
  },
});
</script>

<style scoped>
.discover {
  text-align: left;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 20px;
}

/* smaller mobile devices */
@media (max-width: 320px) {
  h4 {
    font-size: 16px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) {
  h4 {
    font-size: 20px;
  }
}
</style>
