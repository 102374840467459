<template>
  <div class="check-back-badge">
    <span class="check-back-label">Check back later</span>
  </div>
  <div class="title">
    <p class="title-text">{{ webAppTitle }}</p>
    <p class="title-text" :style="{ color: primaryColor }">Have your say!</p>
  </div>
  <div class="card">
    <div class="icon">
      <img src="@/assets/images/clock.png" alt="Clock Icon" class="icon-img" />
    </div>
    <p class="card-text">
      Check back soon to have your say on more of your favourite programmes
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CheckBackPlaceholder.vue",
  props: {
    webAppTitle: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.check-back-badge {
  background-color: #ffc6e5;
  padding: 4px 12px;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 16px;
  display: inline-block;
}

.check-back-label {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.title {
  font-size: 37px;
  font-weight: 600;
}

.title-text {
  margin: 0px;
}

.card {
  background-color: #f3f3f3;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  margin: 8% 15% 8% 15%;
  padding: 10% 10% 15% 10%;
}

.card-text {
  font-weight: 500;
  text-align: center;
  color: #616161;
  font-size: 15px;
}

.icon {
  padding-top: 16px;
}

.icon-img {
  height: 43px;
  width: 43px;
}

/* smaller mobile devices */
@media (max-width: 320px) {
  .title-text {
    font-size: 30px;
  }
  .check-back-label {
    font-size: 11px;
  }
  .card-text {
    font-size: 11px;
  }
  .card {
    padding: 6% 8% 10%;
  }
}

/* standard mobile devices */
@media (min-width: 321px) and (max-width: 373px) {
  .title-text {
    font-size: 32px;
  }
  .check-back-label {
    font-size: 12px;
  }
  .card-text {
    font-size: 13px;
  }
}

/* large mobile devices & small tablets */
@media (min-width: 415px) and (max-width: 540px) {
  .title-text {
    font-size: 45px;
  }
  .check-back-label {
    font-size: 18px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) and (max-width: 768px) {
  .title-text {
    font-size: 48px;
  }
  .check-back-label {
    font-size: 18px;
  }
}
</style>
