<template>
  <div
    class="page"
    :style="{ backgroundColor: 'white' }"
    v-if="pollData != null"
  >
    <div class="image-container">
      <img
        :src="
          pollData.questionImage !== ''
            ? pollData.questionImage
            : require('@/assets/images/default-banner.png')
        "
        alt="Poll Banner Image"
        class="image"
      />
      <button
        @click="$router.push(`/webapps/${serverName}/${webAppId}`)"
        class="back-button"
      >
        <img
          src="@/assets/images/left-arrow.png"
          alt="Back Arrow"
          class="back-icon"
        />
      </button>
    </div>
    <div class="question-container">
      <h1 class="question-main">
        {{ pollData.question }}
      </h1>
      <p class="question-subtitle">
        {{ pollData.description }}
      </p>
    </div>
    <div class="options-container">
      <div
        v-for="alternative in pollData.alternatives"
        :key="alternative.uuid"
        class="option-card"
        @click="selected(alternative.uuid)"
        :style="{
          border:
            selectedOption === alternative.uuid
              ? '5px solid' + primaryColor
              : '5px solid white',
          borderRadius: '20px',
        }"
      >
        <img :src="alternative.image" class="option-image" />
        <div
          class="option-container"
          :style="{
            backgroundColor: secondaryColor,
            borderRadius: alternative.image
              ? '0px 15px 15px 0px'
              : '15px 15px 15px 15px',
          }"
        >
          <p class="option-text">{{ alternative.answer }}</p>
        </div>
      </div>
    </div>
    <div class="vote-button-container">
      <vue-turnstile
        v-if="turnstileSiteKey"
        :site-key="turnstileSiteKey"
        v-model="turnstileToken"
        size="flexible"
        @success="handleTurnstileSuccess"
        @error="handleTurnstileError"
        @expired="handleTurnstileExpired"
      />
      <button
        class="vote-button"
        :style="{
          backgroundColor: !voteDisabled ? primaryColor : '#DFDFDF',
        }"
        @click="sendVote()"
        :disabled="voteDisabled"
        flat
      >
        VOTE
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import brotliPromise from "brotli-wasm";
import { Poll } from "@/types/Poll";
import { WebApp } from "@/types/WebApp";
import VueTurnstile from "vue-turnstile";

export default defineComponent({
  name: "PollsView",
  components: { VueTurnstile },
  data() {
    return {
      pollData: null as Poll | null,
      webAppData: null as WebApp | null,
      selectedOption: null as string | null,
      primaryColor: "#e3890b",
      secondaryColor: "#2748cc",
      serverName: this.$route.params.serverName,
      webAppId: this.$route.params.webAppName,
      pollId: this.$route.params.pollName,
      apiKey: "",
      baseApiUrl: null as string | null,
      turnstileSiteKey: null as string | null,
      voteDisabled: true,
      turnstileToken: "",
    };
  },
  mounted() {
    // fetch poll data
    fetch(`/webapps/${this.serverName}/${this.webAppId}/${this.pollId}.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch poll data (${response.status} ${response.statusText})`
          );
        }
        // read the response body into a buffer
        return response.arrayBuffer().then((buffer) => {
          const uint8Array = new Uint8Array(buffer);
          // attempt to decompress the response
          return brotliPromise.then((brotli) => {
            try {
              // try decompressing
              const decompressedData = brotli.decompress(uint8Array);
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(decompressedData);
              return JSON.parse(jsonString);
            } catch (error) {
              // if decompression fails - parse JSON directly from the buffer
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(uint8Array);
              return JSON.parse(jsonString);
            }
          });
        });
      })
      .then((pollData) => {
        // set poll data
        this.pollData = pollData as Poll;
      })
      .catch((error) => {
        console.error("Error fetching poll data:", error);
      });
    // fetch WebApp data
    fetch(`/webapps/${this.serverName}/${this.webAppId}/webapp.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch WebApp data (${response.status} ${response.statusText})`
          );
        }
        // read the response body into a buffer
        return response.arrayBuffer().then((buffer) => {
          const uint8Array = new Uint8Array(buffer);
          // attempt to decompress the response
          return brotliPromise.then((brotli) => {
            try {
              // try decompressing
              const decompressedData = brotli.decompress(uint8Array);
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(decompressedData);
              return JSON.parse(jsonString);
            } catch (error) {
              // if decompression fails - parse JSON directly from the buffer
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(uint8Array);
              return JSON.parse(jsonString);
            }
          });
        });
      })
      .then((webAppData) => {
        // set WebApp data
        this.webAppData = webAppData as WebApp;
        // set the colour scheme using the chosen WebApp theme colours
        const colorScheme = JSON.parse(this.webAppData.theme);
        this.primaryColor = colorScheme.mainColor;
        this.secondaryColor = colorScheme.secondaryColor;
      })
      .catch((error) => {
        console.error("Error fetching WebApp data:", error);
      });
    // fetch config variables
    fetch(`/config.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch config");
        }
        return response.json();
      })
      .then((data) => {
        this.apiKey = data.devGatewayApiKey;
        this.baseApiUrl = data.apiBaseUrl;
        this.turnstileSiteKey = data.turnstileSiteKey;
      })
      .catch((error) => {
        console.error("Error fetching config file:", error);
      });
  },
  watch: {
    turnstileToken() {
      this.updateVoteDisabled();
    },
    selectedOption() {
      this.updateVoteDisabled();
    },
  },
  methods: {
    updateVoteDisabled() {
      this.voteDisabled = !(this.selectedOption && this.turnstileToken);
    },
    selected(id: string) {
      if (this.selectedOption === id) {
        this.selectedOption = null;
      } else {
        this.selectedOption = id;
      }
    },
    async sendVote() {
      try {
        // verify cloudflare token
        const verificationResponse = await fetch(`/turnstile-verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: this.turnstileToken,
          }),
        });
        const verificationResult = await verificationResponse.json();
        if (verificationResult.success) {
          const apiUrl = this.baseApiUrl + "/votes";
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": this.apiKey,
            },
            body: JSON.stringify({
              serverId: this.serverName,
              pollId: this.pollId,
              optionId: this.selectedOption,
            }),
          });
          if (!response.ok) {
            throw new Error("Failed to send vote");
          }
          const data = await response.json();
          this.$router.push(
            `/webapps/${this.serverName}/${this.webAppId}/${this.pollId}/confirmation`
          );
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    },
    handleTurnstileError(error: Error) {
      this.turnstileToken = "";
      this.updateVoteDisabled();
    },
    handleTurnstileExpired() {
      this.turnstileToken = "";
      this.updateVoteDisabled();
    },
  },
});
</script>

<style scoped>
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back-button {
  position: absolute;
  cursor: pointer;
  top: 20px;
  left: 20px;
  background: transparent;
  border: none;
}

.back-button:hover {
  background-color: #4c6fd0;
}

.back-icon {
  width: 10px;
  height: 15px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.image-container {
  flex: 0 0 23%;
  height: 24.582vh;
}

.question-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 7% 7.5% 1% 7.5%;
  gap: 0;
  justify-content: center;
  color: white;
}

.question-main {
  font-size: 28px;
  margin: 0;
  color: black;
  line-height: 37.24px;
  font-weight: 600;
}
.question-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: rgb(140, 139, 139);
  margin-top: 3%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.options-container {
  overflow-y: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 12.5rem;
  position: relative;
}

.option-card {
  display: flex;
  justify-content: center;
  width: 85%;
  transition: border-color 0.2s ease;
}

.option-container {
  display: flex;
  justify-content: left;
  align-items: center;
  min-height: 100%;
  height: 3.8rem;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.option-image {
  max-height: 4.2rem;
  border-radius: 15px 0px 0px 15px;
  width: 30%;
  object-fit: cover;
}

.option-text {
  font-size: clamp(15px, 5.5vw, 25px);
  color: white;
  text-align: left;
  font-weight: 500;
}

.vote-button-container {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.vote-button {
  width: 86%;
  padding: 16px;
  color: white;
  font-weight: 700;
  border: none;
  border-radius: 40px;
  font-size: 2.029vh;
  transition: background-color 1s ease;
}

/* smaller mobile devices */
@media (max-width: 320px) {
  .question-container {
    padding: 4% 7.5% 1% 7.5%;
  }
  .question-main {
    font-size: 22px;
  }
  .question-subtitle {
    font-size: 11px;
    margin-top: 1%;
  }
  .option-image {
    max-height: 3rem;
  }
  .option-container {
    height: 3rem;
  }
  .vote-button-container {
    bottom: 1.5rem;
  }
  .options-container {
    margin-bottom: 10rem;
    gap: 2px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) {
  .question-container {
    padding: 4% 7.5% 1% 7.5%;
  }
  .question-main {
    font-size: 38px;
  }
  .question-subtitle {
    font-size: 21px;
  }
  .option-image {
    max-height: 4.8rem;
  }
  .option-container {
    height: 4.8rem;
  }
  .options-container {
    margin-bottom: 13.5rem;
  }
  .vote-button-container {
    bottom: 2.5rem;
  }
}
</style>
