<template>
  <router-view />
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Outfit", sans-serif;
  text-align: center;
  color: #000000;
  letter-spacing: -0.5px;
  box-sizing: border-box;
}

@font-face {
  font-family: "Outfit";
  src: url(@/assets/fonts/Outfit-VariableFont_wght.ttf) format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

html,
body {
  margin: 0;
  justify-content: center;
  align-items: center;
}

/* for screens bigger than small tablet, impose mobile dimensions */
@media (min-width: 769px) {
  #app {
    width: 387px;
    height: 838px;
  }
}
</style>
