import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3978a77d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "discover" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PollPreviewCard = _resolveComponent("PollPreviewCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", {
      style: _normalizeStyle({ color: _ctx.primaryColor })
    }, " Discover more from " + _toDisplayString(_ctx.webAppName), 5),
    (_ctx.featuredPoll)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PollPreviewCard, {
            poll: _ctx.featuredPoll,
            featured: true
          }, null, 8, ["poll"])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.polls, (poll) => {
      return (_openBlock(), _createElementBlock("div", {
        key: poll._id
      }, [
        _createVNode(_component_PollPreviewCard, { poll: poll }, null, 8, ["poll"])
      ]))
    }), 128))
  ]))
}