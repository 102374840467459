<template>
  <div
    class="card"
    @click="$router.push(`/webapps/${serverName}/${webAppId}/${poll._id}/vote`)"
  >
    <div class="image-container">
      <img :src="pollImage" alt="Poll Image" class="image" />
    </div>
    <div class="line" :style="{ backgroundColor: primaryColor }"></div>
    <div class="content">
      <p class="question">{{ poll.question }}</p>
      <p class="description">{{ poll.description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Poll } from "@/types/Poll";

export default defineComponent({
  name: "LargePollPreviewCard",
  props: {
    poll: {
      type: Object as PropType<Poll>,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      serverName: this.$route.params.serverName,
      webAppId: this.$route.params.webAppName,
      defaultPollImage: require("@/assets/images/default-banner.png"),
    };
  },
  computed: {
    pollImage(): string {
      return this.poll.questionImage || this.defaultPollImage;
    },
  },
});
</script>

<style scoped>
.card {
  border: 1px solid lightgrey;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.card:active {
  background-color: #f0f0f0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

.image-container {
  height: 18vh;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.line {
  height: 4px;
  width: 100%;
}

.content {
  padding: 1% 5%;
  text-align: center;
}

.question {
  font-weight: 500;
  font-size: 18px;
  line-height: 19.8px;
  margin-top: 6px;
  margin-bottom: 4px;
}

.description {
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 6px;
}

/* smaller mobile devices */
@media (max-width: 320px) {
  .question {
    font-size: 13px;
    line-height: 14.8px;
    margin-bottom: 4px;
    margin-top: 3px;
  }
  .description {
    font-size: 9px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) {
  .question {
    font-size: 19px;
    line-height: 14.8px;
    margin-bottom: 6px;
    margin-top: 3px;
  }
  .description {
    font-size: 13px;
  }
}
</style>
