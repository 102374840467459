import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12d831b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "question" }
const _hoisted_5 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/webapps/${_ctx.serverName}/${_ctx.webAppId}/${_ctx.poll._id}/vote`)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.pollImage,
        alt: "Poll Image",
        class: "image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", {
      class: "line",
      style: _normalizeStyle({ backgroundColor: _ctx.primaryColor })
    }, null, 4),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.poll.question), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.poll.description), 1)
    ])
  ]))
}