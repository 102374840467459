<template>
  <div class="vote-confirmation">
    <div v-if="webAppData">
      <div class="header-image">
        <img :src="pollImage" alt="Poll Image" class="image" />
        <button
          @click="$router.push(`/webapps/${serverName}/${webAppId}`)"
          class="back-button"
        >
          <img
            src="@/assets/images/left-arrow.png"
            alt="Back Arrow"
            class="back-icon"
          />
        </button>
      </div>
      <div>
        <h1>
          <span>Thank you</span>
          <span :style="{ color: primaryColor }">for your vote!</span>
        </h1>
        <div class="message-container">
          <p class="message">{{ message }}</p>
        </div>
      </div>
      <div class="discover-section">
        <hr class="divider" />
        <DiscoverPolls
          :primaryColor="primaryColor"
          :secondaryColor="secondaryColor"
          :webAppName="webAppData?.title"
          :featuredPoll="featuredPoll"
          :polls="filteredPolls"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import brotliPromise from "brotli-wasm";
import DiscoverPolls from "@/components/DiscoverPolls.vue";
import { WebApp } from "@/types/WebApp";
import { Poll } from "@/types/Poll";

export default defineComponent({
  name: "VoteConfirmationView",
  components: {
    DiscoverPolls,
  },
  data() {
    return {
      serverName: this.$route.params.serverName,
      webAppId: this.$route.params.webAppName,
      pollId: Number(this.$route.params.pollName),
      webAppData: null as WebApp | null,
      primaryColor: "",
      secondaryColor: "",
      poll: null as Poll | null,
      polls: [] as Poll[],
      featuredPoll: null as Poll | null,
      defaultBannerImage: require("@/assets/images/default-banner.png"),
      // default thank you message instructions
      message: "Your vote has been logged. Tune into the show for the results",
    };
  },
  computed: {
    pollImage(): string {
      return this.poll?.questionImage || this.defaultBannerImage;
    },
    filteredPolls(): Poll[] {
      // only show active polls and do not repeat the featured poll
      return this.polls.filter(
        (poll) =>
          poll.pollStatus === "active" && poll._id !== this.featuredPoll?._id
      );
    },
  },
  mounted() {
    // fetch WebApp data
    fetch(`/webapps/${this.serverName}/${this.webAppId}/webapp.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch WebApp data (${response.status} ${response.statusText})`
          );
        }
        // read the response body into a buffer
        return response.arrayBuffer().then((buffer) => {
          const uint8Array = new Uint8Array(buffer);
          // attempt to decompress the response
          return brotliPromise.then((brotli) => {
            try {
              // try decompressing
              const decompressedData = brotli.decompress(uint8Array);
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(decompressedData);
              return JSON.parse(jsonString);
            } catch (error) {
              // if decompression fails - parse JSON directly from the buffer
              const textDecoder = new TextDecoder();
              const jsonString = textDecoder.decode(uint8Array);
              return JSON.parse(jsonString);
            }
          });
        });
      })
      .then((webAppData) => {
        // set WebApp data
        this.webAppData = webAppData as WebApp;
        // set the colour scheme using the chosen WebApp theme colours
        const colorScheme = JSON.parse(this.webAppData.theme);
        this.primaryColor = colorScheme.mainColor;
        this.secondaryColor = colorScheme.secondaryColor;
        // set all poll data belonging to that WebApp
        if (webAppData.apps) {
          this.polls = this.webAppData.apps;
          // find the current poll that has been voted on
          this.poll =
            this.polls.find((poll) => poll._id === this.pollId) || null;
        }
        // set the thank you message instructions
        if (this.poll?.instructions) {
          this.message = this.poll.instructions;
        }
        // set the featured poll
        if (webAppData.featuredPoll) {
          const featuredPollId = this.webAppData.featuredPoll;
          const featuredPoll =
            this.polls.find((poll) => poll._id === featuredPollId) || null;
          // only display the featured poll if it's active
          if (featuredPoll && featuredPoll.pollStatus === "active") {
            this.featuredPoll = featuredPoll;
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching WebApp data:", error);
      });
  },
});
</script>

<style scoped>
.vote-confirmation {
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #fff;
  position: relative;
}

h1 {
  font-size: 37px;
  font-weight: 600;
  color: #333;
  line-height: 1;
}

span {
  display: block;
}

.header-image {
  grid-row: 1 / 2;
}

.image {
  width: 100%;
}

.back-button {
  position: absolute;
  cursor: pointer;
  top: 20px;
  left: 20px;
  background: transparent;
  border: none;
}

.back-button:hover {
  background-color: #4c6fd0;
}

.back-icon {
  width: 10px;
  height: 15px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}

.message {
  color: #9b9b9b;
  font-weight: 600;
  font-size: 16px;
  margin-block-start: 0;
}

.message-container {
  width: 70%;
  margin: 0 auto;
}

.discover-section {
  width: 85%;
  margin: 20px auto;
  padding-bottom: 5%;
}

.divider {
  border: 1.5px solid rgb(223, 223, 223);
  border-style: none none solid none;
}

/* smaller mobile devices */
@media (max-width: 320px) {
  h1 {
    font-size: 30px;
  }
  .message {
    font-size: 14px;
  }
}

/* tablets & very large mobile devices */
@media (min-width: 541px) {
  h1 {
    font-size: 45px;
  }
  .message {
    font-size: 18px;
  }
}
</style>
